import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Section from '~components/global/section'
import ProductTile from '~components/product/productTile'
import {
  LevelWrap,
  TagLine,
  ItalicTagLine,
  Headline,
  Buttons,
  Back,
  Top,
  Next
} from '~components/quiz/quizStyles'
import { Link } from 'gatsby'

const Recommendation = ({ className, product, onBack }) => {
  return (
    <LevelWrap>
      <Top>
        <ResultsHeadline className='l-sans'>Handpicked, for you</ResultsHeadline>
        <ResultsTagLine>This one has ‘you’ written all over it.</ResultsTagLine>
        <ProductTile product={product}/>
        {console.log(product)}
      </Top>
      <StyledButtons>
        <Back flipped onClick={()=> onBack()}>Back</Back>
        <AllCoffee to='/coffee'>Shop All</AllCoffee>
      </StyledButtons>
    </LevelWrap>
  )
}

const ResultsHeadline = styled(Headline)`
  margin-bottom: 1rem;
`
const ResultsTagLine = styled(ItalicTagLine)`
  margin-bottom: 3rem;
`
const AllCoffee = styled(Next)`

`
const StyledButtons = styled(Buttons)`
  ${mobile}{
    margin-bottom: 1rem;
  }
`

Recommendation.propTypes = {
  className: PropTypes.string
}

export default Recommendation