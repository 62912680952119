const capitalizeFirstLetter = string => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1);
}

const quizLevels = (userStage) => {
  return (
    {
      levels: [
        {
          title: 'Approach level',
          prompt: `How much do you know about coffee ${capitalizeFirstLetter(userStage?.name)}?`,
          imageLeft: '/images/quiz-approach-level.png',
          imageMobile: '/images/quiz-approach-level.png',
          values: [
            {
              name: 'I´m a newbie to coffee',
            },
            {
              name: 'A little bit, I know what I like',
            },
            {
              name: 'I\'m a coffee snob',
            }
          ]
        },
        {
          title: 'Intensity Level',
          prompt: 'It\'s Monday morning and your body needs its dose of caffeine, your ideal coffee is...',
          imageRight: '/images/quiz-intensity-level.png',
          imageMobile: '/images/quiz-intensity-level.png',
          options: [
            {
              values: [
                {
                  name: 'black'
                },
                {
                  name: 'with milk'
                },
                {
                  name: 'it depends on my mood'
                }
              ]
            },
            {
              values: [
                {
                  name: 'black'
                },
                {
                  name: 'with milk'
                },
                {
                  name: 'it depends on my mood'
                }
              ]
            },
            {
              values: [
                {
                  name: 'Bold and Strong'
                },
                {
                  name: 'Medium-bodied'
                },
                {
                  name: 'Light and creamy'
                }
              ]
            }
          ]
        },
        {
          title: 'body',
          options: [
            {
              prompt: 'When you are in a coffee shop, what do you normally order?',
              imageLeft: '/images/quiz-body-1.png',
              imageRight: '/images/quiz-body-2.png',
              imageMobile: '/images/quiz-body-1.png',
              values: 
              userStage.intensityLevel === 0 ? 
                [
                  {
                    name: 'Espresso'
                  },
                  {
                    name: 'Long Black'
                  },
                ] 
              : userStage.intensityLevel === 1 ?
                [
                  {
                    name: 'Flat White'
                  },
                  {
                    name: 'Cappucino'
                  },
                  {
                    name: 'Latte'
                  },
                  {
                    name: 'Mocha'
                  }
                ]
                : 
                [
                  {
                    name: 'Espresso'
                  },
                  {
                    name: 'Long Black'
                  },
                  {
                    name: 'Flat White'
                  },
                  {
                    name: 'Cappucino'
                  },
                  {
                    name: 'Latte'
                  },
                  {
                    name: 'Mocha'
                  }
                ]
            },
            {
              prompt: 'You head to your favourite vineyard with your friends for lunch, what type of wine would your choose?',
              imageLeft: '/images/quiz-body-wine.png',
              imageMobile: '/images/quiz-body-wine.png',
              values: [
                {
                  name: 'Pinot noir'
                },
                {
                  name: 'Cabernet france'
                },
                {
                  name: 'Shiraz'
                }
              ]
            },
            {
              prompt: 'Would you rather have your coffee being a blend of two delicious beans or keep it nice and simple with a single origin?',
              imageRight: '/images/quiz-body-origin-blend.png',
              imageMobile: '/images/quiz-body-origin-blend.png',
              values: [
                {
                  name: 'Single Origin'
                },
                {
                  name: 'Blend'
                },
              ]
            },
          ]
        },
        {
          title: 'Flavour Notes',
          options: [
            {
              prompt: 'If you had to choose one "pick me up" snack to have for the rest of your life, What would it be?',
              imageLeft: '/images/quiz-flavour-notes-1.png',
              imageRight: '/images/quiz-flavour-notes-2.png',
              imageMobile: '/images/quiz-flavour-notes-1.png',
              values: [
                {
                  name: 'Nutty granola bar'
                },
                {
                  name: 'Yoghurt & fruit'
                },
                {
                  name: 'Spiced popcorn'
                },
                {
                  name: 'Indulgent Biscoff Cookie'
                },
                {
                  name: 'Dark Chocolate'
                }
              ]
            },
            {
              prompt: 'Leaving all calories count aside, what\'s your perfect way to have a scone?',
              imageRight: '/images/quiz-flavour-notes-scone.png',
              imageMobile: '/images/quiz-flavour-notes-scone.png',
              values: [
                {
                  name: 'Just plain for me'
                },
                {
                  name: 'A light spread of jam would be nice'
                },
                {
                  name: 'Jam and whipped cream, please!'
                }
              ]
            },
            {
              prompt: 'How do you feel about sweetness when it comes to drinks?',
              imageLeft: '/images/quiz-sweetness.png',
              imageMobile: '/images/quiz-sweetness.png',
              slider: true,
              values: [
                {
                  name: 'Keep it low'
                },
                {
                  name: 'Medium sweetness is ok	'
                },
                {
                  name: 'Sweet all the way!'
                },
              ]
            },
          ]
        },
        {
          title: 'Level 5',
          preHeading: 'Flavour notes',
          options: [
            {
              prompt: undefined,
            },
            {
              prompt: 'If you had to choose one "pick me up" snack to have for the rest of your life, What would it be?',
              imageLeft: '/images/quiz-flavour-notes-1.png',
              imageRight: '/images/quiz-flavour-notes-2.png',
              imageMobile: '/images/quiz-flavour-notes-1.png',
              values: [
                {
                  name: 'Nutty granola bar'
                },
                {
                  name: 'Yoghurt & fruit'
                },
                {
                  name: 'Spiced popcorn'
                },
                {
                  name: 'Indulgent Biscoff Cookie'
                },
                {
                  name: 'Dark Chocolate'
                }
              ]
            },
            {
              prompt: 'And where do you stand when it comes to acidity?',
              imageRight: '/images/quiz-flavour-notes-acidity.png',
              imageMobile: '/images/quiz-flavour-notes-acidity.png',
              slider: true,
              values: [
                {
                  name: 'Keep it low'
                },
                {
                  name: 'Medium acidity is ok'
                },
                {
                  name: 'Love that for me!'
                },
              ]
            },
          ]
        },
        {
          preHeading: 'Flavour notes',
          title: 'Level 6',
          options: [
            {
              prompt: undefined,
            },
            {
              prompt: undefined,
            },
            {
              prompt: 'Ok we are getting close now. Please choose your favourite note for your coffee.',
              imageLeft: '/images/quiz-flavour-notes-1.png',
              imageRight: '/images/quiz-flavour-notes-2.png',
              imageMobile: '/images/quiz-flavour-notes-1.png',
              values: [
                {
                  name: 'Sweet'
                },
                {
                  name: 'Fruity'
                },
                {
                  name: 'Citrus'
                },
                {
                  name: 'Spices'
                },
                {
                  name: 'Nutty'
                },
                {
                  name: 'Chocolate'
                },
              ]
            },
          ]
        },
      ]
    }
  )
}

export default quizLevels