import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import {
  StyledTile,
  TileHeading,
  TileText,
} from '~components/quiz/quizStyles'

const Tile = ({ className, inverse, onSelect, isSelected, heading, rowOfFour }) => {
  const [hover, setHover] = useState(false)
  const [selected, setSelected] = useState()

  const selectTile = () => {
    setSelected(true)
    if( onSelect ) {
      onSelect()
    }
  }

  useEffect(() => {
    setSelected(isSelected)
  }, [isSelected])

  return (
    <StyledTile 
      rowOfFour={rowOfFour}
      className={className} 
      inverse={hover || selected}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={()=> selectTile()}
    >
      <TileHeading className='m-caps'>{heading}</TileHeading>
    </StyledTile>
  )
}

const Wrap = styled.div`
  
`

Tile.propTypes = {
  className: PropTypes.string
}

export default Tile