import React, { useState, useLayoutEffect } from 'react'
import { Select, RadioButton, Incrementer, SubmitButton } from "~components/global/formElements"
import { Loop } from "~components/global/svg"
import useCheckoutSettings from "~utils/useCheckoutSettings"
import toFixed from "~utils/toFixed"

const AddToCart = ({
  sub,
  types,
  unit,
  multiplier,
  onAddToCart,
  onVariantChange,
  price,
  subDiscount,
  showUnits,
  productId,
}) => {
  const { frequencyOptions, cartTitle } = useCheckoutSettings(productId)
  const [qty, setQty] = useState(1)
  const [frequency, setFrequency] = useState(null)
  const [subSelected, setSubSelected] = useState(false)

  useLayoutEffect(() => {
    if (!frequencyOptions || !frequencyOptions.length) return
    if (sub) {
      const shouldSelectSub = window.previousPath?.includes("subscribe-save")
      setFrequency(frequencyOptions[0])
      setSubSelected(shouldSelectSub)
    }
  }, [frequencyOptions])

  return (
    <div className="add-to-cart">
      {types && types.length > 1 && (
        <div className="add-to-cart__variant">
          <Select
            name="variant"
            label="Product Type"
            onChange={e => {
              onVariantChange(e)
            }}
            options={types}
          />
        </div>
      )}
      {sub && Array.isArray(frequencyOptions) && (
        <div className="add-to-cart__sub">
          <RadioButton
            onClick={() => setSubSelected(!subSelected)}
            selected={subSelected}
            secondaryIcon={
              <span>${toFixed(price * ((100 - subDiscount) / 100), 2)}</span>
            }
          >
            Subscribe & save <span>{subDiscount}%</span>
          </RadioButton>
          {subSelected && (
            <Select
              name="delivery-frequency"
              primaryIcon={<Loop />}
              onChange={e => {
                setFrequency(e)
              }}
              options={frequencyOptions}
              defaultValue={frequency.value ?? null}
            ></Select>
          )}
        </div>
      )}
      <div className="add-to-cart__one-time">
        <RadioButton
          selected={!subSelected}
          onClick={() => setSubSelected(sub ? !subSelected : false)}
          secondaryIcon={<span>${price}</span>}
        >
          One-time purchase
        </RadioButton>
      </div>
      <div className="add-to-cart__finalise">
        <Incrementer
          multiplier={showUnits && multiplier}
          unitName={showUnits && unit}
          initialValue={1}
          onChange={v => setQty(v)}
        />
        <SubmitButton onClick={() => {
          onAddToCart({
          frequency: subSelected ? frequency.value : null,
          qty: qty,
          sellingPlanId: subSelected ? frequency.sellingPlanId : null,
          frequencyUnit: subSelected ? frequency.text : null,
        })}}>
          Add to {cartTitle}
        </SubmitButton>
      </div>
    </div>
  )
}

export default AddToCart
