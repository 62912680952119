import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import {
  TagLine,
  Headline,
  Buttons,
  Next,
  Back,
  LevelWrap,
  Top,
  Tiles,
  DisabledMessage,
  LeftImage,
  RightImage,
  LeftImageBlock,
  RightImageBlock,
  ImageMobBlock,
  ImageMob
} from '~components/quiz/quizStyles'
import Tile from '~components/quiz/tile'
import DragSlider from '~components/global/dragSlider'

const Level = ({ onNext, onBack, tiles, prevSelected, title, onChange, slider, imageLeft, imageRight, imageMob }) => {

  const [selectedTile, setSelectedTile] = useState(undefined)
  const [diabledMessage, setDisabledMessage] = useState(false)

  useEffect(() => {
    if(prevSelected || prevSelected === 0) {
      setSelectedTile(prevSelected)
    }
  }, [])

  useEffect(() => {
    if (onChange) {
      onChange(selectedTile)
    }
  }, [selectedTile])

  const showDisableMessage = () => {
    setDisabledMessage(true)
  }

  return (
    <LevelWrap>
      <Top>
      <Headline className='l-serif'>{title}</Headline>
      {slider ? 
        <DragSlider 
          onSelect={(tile)=> setSelectedTile(tile)}
          options={tiles}
        />
        :
        <Tiles rowOfFour={(tiles?.length === 4) || (tiles?.length === 7)}>
          {tiles?.map((tile, i) => (
            <Tile 
              rowOfFour={(tiles?.length === 4) || (tiles?.length === 7)}
              key={tile.name} 
              heading={tile.name} 
              onSelect={()=> setSelectedTile(i)}
              isSelected={selectedTile === i}
            />
          ))}
        </Tiles>
      }
      </Top>
      <Buttons>
        <DisabledMessage className='s-caps' show={diabledMessage}>Select an option to continue</DisabledMessage>
        <Back flipped onClick={()=> onBack()}>Back</Back>
        <Next 
          onClick={selectedTile === undefined ? () => showDisableMessage() : () => onNext(selectedTile)}
          disabled={selectedTile === undefined}
        >Next</Next>
      </Buttons>
      <ImageMobBlock>
        <ImageMob src={imageMob} alt='quiz-image'/>
      </ImageMobBlock>
      <LeftImageBlock show={imageLeft}>
        <LeftImage src={imageLeft} alt='quiz-image'/>
      </LeftImageBlock>
      <RightImageBlock show={imageRight}>
        <RightImage src={imageRight} alt='quiz-image'/>
      </RightImageBlock>
    </LevelWrap>
  )
}

Level.propTypes = {
  className: PropTypes.string
}

export default Level