const quizResults = [
  // I'm a newbie to coffee > Black > Expresso
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 0, // Expresso
    flavourNotes: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 0, // Expresso
    flavourNotes: 1, // Yoghurt & fruit
    result: "Huehuetenango"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 0, // Expresso
    flavourNotes: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 0, // Expresso
    flavourNotes: 3, // Indulgent Biscoff Cookie	
    result: "Calexte"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 0, // Expresso
    flavourNotes: 4, // Dark Chocolate
    result: "Munro"
  },
  // I'm a newbie to coffee > Black > Long Black
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 1, // Long Black
    flavourNotes: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 1, // Long Black
    flavourNotes: 1, // Yoghurt & fruit
    result: "Huehuetenango"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 1, // Long Black
    flavourNotes: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 1, // Long Black
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Calexte"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 0, // Black
    body: 1, // Long Black
    flavourNotes: 4, // Dark Chocolate
    result: "Munro"
  },
  // I'm a newbie to coffee > With Milk > Flat White
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 0, // Flat White
    flavourNotes: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 0, // Flat White
    flavourNotes: 1, // Yoghurt & fruit
    result: "Florentino"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 0, // Flat White
    flavourNotes: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 0, // Flat White
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Bezzera"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 0, // Flat White
    flavourNotes: 4, // Dark Chocolate
    result: "Bancroft"
  },
  // I'm a newbie to coffee > With Milk > Cappucino
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 1, // Cappucino
    flavourNotes: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 1, // Cappucino
    flavourNotes: 1, // Yoghurt & fruit
    result: "Florentino"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 1, // Cappucino
    flavourNotes: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 1, // Cappucino
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Bezzera"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 1, // Cappucino
    flavourNotes: 4, // Dark Chocolate
    result: "Bancroft"
  },
  // I'm a newbie to coffee > With Milk > Latte
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 2, // Latte
    flavourNotes: 0, // Nutty granola bar
    result: "Bancroft"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 2, // Latte
    flavourNotes: 1, // Yoghurt & fruit
    result: "Florentino"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 2, // Latte
    flavourNotes: 2, // Spiced popcorn
    result: "Tarrazú"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 2, // Latte
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Bezzera"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 2, // Latte
    flavourNotes: 4, // Dark Chocolate
    result: "Calexte"
  },
  // I'm a newbie to coffee > With Milk > Mocha 
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 1, // With Milk
    body: 3, // Mocha
    result: "Munro"
  },
  // I'm a newbie to coffee > It depends on my mood > Expresso
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 0, // Expresso
    flavourNotes: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 0, // Expresso
    flavourNotes: 1, // Yoghurt & fruit
    result: "Munro"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 0, // Expresso
    flavourNotes: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 0, // Expresso
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Huehuetenango"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 0, // Expresso
    flavourNotes: 4, // Dark Chocolate
    result: "Munro"
  },
  // I'm a newbie to coffee > It depends on my mood > Long Black
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 1, // Long Black
    flavourNotes: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 1, // Long Black
    flavourNotes: 1, // Yoghurt & fruit
    result: "Mbeya"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 1, // Long Black
    flavourNotes: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 1, // Long Black
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Mbeya"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 1, // Long Black
    flavourNotes: 4, // Dark Chocolate
    result: "Munro"
  },
  // I'm a newbie to coffee > It depends on my mood > Flat White
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 2, // Flat White
    flavourNotes: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 2, // Flat White
    flavourNotes: 1, // Yoghurt & fruit
    result: "Bancroft"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 2, // Flat White
    flavourNotes: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 2, // Flat White
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Bancroft"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 2, // Flat White
    flavourNotes: 4, // Dark Chocolate
    result: "Bezzera"
  },
  // I'm a newbie to coffee > It depends on my mood > Capuccino
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 3, // Capuccino
    flavourNotes: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 3, // Capuccino
    flavourNotes: 1, // Yoghurt & fruit
    result: "Bancroft"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 3, // Capuccino
    flavourNotes: 2, // Spiced popcorn
    result: "Tarrazú"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 3, // Capuccino
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 3, // Capuccino
    flavourNotes: 4, // Dark Chocolate
    result: "Florentino"
  },
  // I'm a newbie to coffee > It depends on my mood > Latte
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 4, // Latte
    flavourNotes: 0, // Nutty granola bar
    result: "Bancroft"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 4, // Latte
    flavourNotes: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 4, // Latte
    flavourNotes: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 4, // Latte
    flavourNotes: 3, // Indulgent Biscoff Cookie
    result: "Bancroft"
  },
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 4, // Latte
    flavourNotes: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // I'm a newbie to coffee > It depends on my mood > Mocha
  {
    approachLevel: 0, // I'm a newbie to coffee
    intensityLevel: 2, // It depends on my mood
    body: 5, // Mocha
    result: "Munro"
  },


  // A litlle bit, I know what I like > Black > Pino Noir > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Calexte"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Munro"
  },
  // A litlle bit, I know what I like > Black > Pino Noir > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Indulgent Biscoff Cookie
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 4, // Dark Chocolate
    result: "Munro"
  },
  // A litlle bit, I know what I like > Black > Pino Noir > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > Black > Cabernet france > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Fasoli"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Bezzera"
  },
  // A litlle bit, I know what I like > Black > Cabernet france > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Fasoli"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Indulgent Biscoff Cookie
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 4, // Dark Chocolate
    result: "Munro"
  },
  // A litlle bit, I know what I like > Black > Cabernet france > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 1, // Cabernet france
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > Black > Shiraz > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Bezzera"
  },
  // A litlle bit, I know what I like > Black > Shiraz > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Indulgent Biscoff Cookie
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 4, // Dark Chocolate
    result: "Bancroft"
  },
  // A litlle bit, I know what I like > Black > Shiraz > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 0, // Black
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > With Milk > Pino Noir > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Florentino"
  },
  // A litlle bit, I know what I like > With Milk > Pino Noir > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Indulgent Biscoff Cookie
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 4, // Dark Chocolate
    result: "Bancroft"
  },
  // A litlle bit, I know what I l1ke > With Milk > Pino Noir > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > With Milk > CABERNET FRANCE > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Bezzera"
  },
  // A litlle bit, I know what I like > With Milk > CABERNET FRANCE > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Indulgent Biscoff Cookie
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 4, // Dark Chocolate
    result: "Bancroft"
  },
  // A litlle bit, I know what I like > With Milk > CABERNET FRANCE > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Bezerra"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 1, // CABERNET FRANCE
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > With Milk > Shiraz > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Bezzera"
  },
  // A litlle bit, I know what I like > With Milk > Shiraz > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > With Milk > Shiraz > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 1, // With Milk
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > It depends on my mood > Pino Noir > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Bezzera"
  },
  // A litlle bit, I know what I like > It depends on my mood  > Pino Noir > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Indulgent Biscoff Cookie
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 4, // Dark Chocolate
    result: "Munro"
  },
  // A litlle bit, I know what I like > It depends on my mood > Pino Noir > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 0, // Pino Noir
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > It depends on my mood > Cabernet France > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Calexte"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Fasoli"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Bezzera"
  },
  // A litlle bit, I know what I like > It depends on my mood > Cabernet France > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Indulgent Biscoff Cookie
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 4, // Dark Chocolate
    result: "Munro"
  },
  // A litlle bit, I know what I like > It depends on my mood > Cabernet France > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Munro"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Fasoli"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Fasoli"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 1, // Cabernet France
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },
  // A litlle bit, I know what I like > It depends on my mood > Shiraz > Just plain for me
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 0, // Nutty granola bar
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 1, // Yoghurt & fruit
    result: "Florentino"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 3, // Indulgent Biscoff Cookie
    result: "Mbeya"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 0, // Just plain for me
    level5: 4, // Dark Chocolate
    result: "Bezzera"
  },
  // A litlle bit, I know what I like > It depends on my mood > Shiraz > A light spread of jam would be nice
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 0, // Nutty granola bar
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 1, // Yoghurt & fruit
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 2, // Spiced popcorn
    result: "Huehuetenango"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 3, // Indulgent Biscoff Cookie
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 1, // A light spread of jam would be nice
    level5: 4, // Dark Chocolate
    result: "Bancroft"
  },
  // A litlle bit, I know what I like > It depends on my mood > Shiraz > Jam and whipped cream, please!
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 0, // Nutty granola bar
    result: "Bancroft"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 1, // Yoghurt & fruit
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 2, // Spiced popcorn
    result: "Bezzera"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 3, // Indulgent Biscoff Cookie
    result: "Tarrazú"
  },
  {
    approachLevel: 1, // A litlle bit, I know what I like
    intensityLevel: 2, // It depends on my mood 
    body: 2, // Shiraz
    flavourNotes: 2, // Jam and whipped cream, please!
    level5: 4, // Dark Chocolate
    result: "Tarrazú"
  },

  // I'm a coffee snob > Bold and strong > Single Origin > Keep it low (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Single Origin > Keep it low (Sweetness) > Medium Acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Single Origin > Keep it low (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Single Origin > Medium sweetness is ok (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Single Origin > Medium sweetness is ok (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Single Origin > Medium sweetness is ok (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Single Origin > Sweet all the way! (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Single Origin > Sweet all the way! (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Single Origin > Sweet all the way! (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Bold and strong > Blend > Keep it low (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Florentino"
  },
  // I'm a coffee snob > Bold and strong > Blend > Keep it low (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Bancroft"
  },
  // I'm a coffee snob > Bold and strong > Blend > Keep it low (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Bezzera"
  },
  // I'm a coffee snob > Bold and strong > Blend > Medium Sweetness is ok (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Bancroft"
  },
  // I'm a coffee snob > Bold and strong > Blend > Medium Sweetness is ok (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Bancroft"
  },
  // I'm a coffee snob > Bold and strong > Blend > Medium Sweetness is ok (Sweetness) > Love that for me (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 0, // Sweetened
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 1, // Fruity
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 2, // Citrus
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 3, // Spices
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 4, // Nutty
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 5, // Chocolate
    result: "Florentino"
  },
  // I'm a coffee snob > Bold and strong > Blend > Sweet all the way! (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Bezzera"
  },
  // I'm a coffee snob > Bold and strong > Blend > Sweet all the way! (Sweetness) > Medium Acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Bancroft"
  },
  // I'm a coffee snob > Bold and strong > Blend > Sweet all the way! (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Bancroft"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Bezzera"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 0, // Bold and strong
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Florentino"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Keep it low (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Keep it low (Sweetness) > Medium Acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Keep it low (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Medium sweetness is ok (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Medium sweetness is ok (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Medium sweetness is ok (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Tarrazú"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Sweet all the way! (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Sweet all the way! (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Single Origin > Sweet all the way! (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Tarrazú"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Keep it low (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Florentino"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Munro"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Keep it low (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Munro"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Keep it low (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Munro"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Medium Sweetness is ok (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Munro"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Medium Sweetness is ok (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Fasoli"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Medium Sweetness is ok (Sweetness) > Love that for me (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 3, // Spices
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 4, // Nutty
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 5, // Chocolate
    result: "Munro"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Sweet all the way! (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Munro"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Sweet all the way! (Sweetness) > Medium Acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Munro"
  },
  // I'm a coffee snob > Medium Bodied > Blend > Sweet all the way! (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Munro"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 1, // Medium Bodied
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Fasoli"
  },


  // I'm a coffee snob > Light and creamy > Single Origin > Keep it low (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Huehuetenango"
  },
  // I'm a coffee snob > Light and creamy > Single Origin > Keep it low (Sweetness) > Medium Acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Huehuetenango"
  },
  // I'm a coffee snob > Light and creamy > Single Origin > Keep it low (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Mbeya"
  },
  // I'm a coffee snob > Light and creamy > Single Origin > Medium sweetness is ok (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Huehuetenango"
  },
  // I'm a coffee snob > Light and creamy > Single Origin > Medium sweetness is ok (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Huehuetenango"
  },
  // I'm a coffee snob > Light and creamy > Single Origin > Medium sweetness is ok (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Mbeya"
  },
  // I'm a coffee snob > Light and creamy > Single Origin > Sweet all the way! (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Huehuetenango"
  },
  // I'm a coffee snob > Light and creamy > Single Origin > Sweet all the way! (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Mbeya"
  },
  // I'm a coffee snob > Light and creamy > Single Origin > Sweet all the way! (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Huehuetenango"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Mbeya"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 0, // Single Origin
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Mbeya"
  },
  // I'm a coffee snob > Light and creamy > Blend > Keep it low (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Fasoli"
  },
  // I'm a coffee snob > Light and creamy > Blend > Keep it low (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Fasoli"
  },
  // I'm a coffee snob > Light and creamy > Blend > Keep it low (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 0, // Keep it low (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Calexte"
  },
  // I'm a coffee snob > Light and creamy > Blend > Medium Sweetness is ok (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Calexte"
  },
  // I'm a coffee snob > Light and creamy > Blend > Medium Sweetness is ok (Sweetness) > Medium acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Calexte"
  },
  // I'm a coffee snob > Light and creamy > Blend > Medium Sweetness is ok (Sweetness) > Love that for me (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 3, // Spices
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 1, // Medium sweetness is ok (Sweetness)
    level5: 2, // Love that for me (Acidity)
    level6: 5, // Chocolate
    result: "Calexte"
  },
  // I'm a coffee snob > Light and creamy > Blend > Sweet all the way! (Sweetness) > Keep it low (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 0, // Sweetened
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 2, // Citrus
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 3, // Spices
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 0, // Keep it low (Acidity)
    level6: 5, // Chocolate
    result: "Fasoli"
  },
  // I'm a coffee snob > Light and creamy > Blend > Sweet all the way! (Sweetness) > Medium Acidity is ok (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 3, // Spices
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 1, // Medium acidity is ok (Acidity)
    level6: 5, // Chocolate
    result: "Fasoli"
  },
  // I'm a coffee snob > Light and creamy > Blend > Sweet all the way! (Sweetness) > Love that for me! (Acidity)
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 0, // Sweetened
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 1, // Fruity
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 2, // Citrus
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 3, // Spices
    result: "Fasoli"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 4, // Nutty
    result: "Calexte"
  },
  {
    approachLevel: 2, // I'm a coffee snob
    intensityLevel: 2, // Light and creamy
    body: 1, // Blend
    flavourNotes: 2, // Sweet all the way! (Sweetness)
    level5: 2, // Love that for me! (Acidity)
    level6: 5, // Chocolate
    result: "Fasoli"
  },
]

export default quizResults