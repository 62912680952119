import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Img from "gatsby-image"
import { Link, navigate } from 'gatsby'
import resolveLink from '~utils/resolveLink'
import AddToCart from "~components/cart/addToCart"
import useCheckoutSettings from "~utils/useCheckoutSettings"
import toFixed from "~utils/toFixed"
import { useCart } from "~context/siteContext"


const ProductTile = ({ className, product }) => {

  const { rechargeDiscount } = useCheckoutSettings()
  const { addItem } = useCart()

  const addToCart = options => {
    navigate(`?added_from_quiz`)
    const item = {
      _rawShopify: product?.content._rawShopify,
      vId: product?.content?.shopify?.defaultVariant?.variantId,
      title: product?.content?.main?.defaultGroup?.title,
      subTitle: product?.content?.main?.title,
      fluid: product?.content?.main?.basic?.productImages[0]?.asset?.fluid,
      subscribe: options.frequency > 0,
      price: product?.content?.shopify?.defaultVariant?.price,
      subPrice: toFixed((product?.content?.shopify?.defaultVariant?.price * ((100 - rechargeDiscount) / 100)), 2),
      qty: options.qty,
      frequency: options.frequency,
      sellingPlanId: options.sellingPlanId,
      unit: options.frequencyUnit,
      list: window.location.pathname
    }
    addItem(item, true)
    // It's in the cart 🎉
  }

  return (
    <Wrap className={className}>
      <Heading className='m-caps'>{product?.content?.main?.title}</Heading>
      <ImageBlock>
        <Img fluid={product?.content?.main?.basic?.productImages[0]?.asset?.fluid} alt="" />
        <ViewLink to={resolveLink(product)} className='xs-serif'>View Product</ViewLink>
      </ImageBlock>
      <Info>  
      <TagLine className='s-caps'>{product?.content?.main?.basic?.tagline}</TagLine>
      <Meta className="dashed">
        {product?.content?.main?.basic?.description?.map(point => (
          <li>{point}</li>
        ))}
      </Meta>
      <AddToCart
        sub={true}
        price={product?.content?.shopify?.defaultVariant?.price}
        subDiscount={rechargeDiscount}
        showUnits={product?.content?.main?.basic?.unitMessage}
        unit={product?.content?.main?.basic?.unitName}
        multiplier={product?.content?.main?.basic?.itemsPerUnit}
        onAddToCart={addToCart}
        productId={product?.content._rawShopify.productId}
        />
      </Info>
    </Wrap>
  )
}

const Wrap = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  padding: 0.8rem 1rem;
  background-color: #fff;
  border-radius: 10px;
  text-align: left;
  margin-bottom: 2rem;
  ${mobile}{
    grid-template-columns: 1fr;
    width: 100%;
    box-sizing: border-box;
  }
`
const Heading = styled.div`
  grid-column: span 2;
  color: #EC873E;
  margin-bottom: 0.7rem;
  ${mobile}{
    grid-column: span 1;
  }
`
const ImageBlock = styled.div`
`
const ViewLink = styled(Link)`
  font-style: italic;
`
const Info = styled.div`
  border-top: 1px solid #000;
  padding-top: 1rem;
`
const Meta = styled.ul`
  margin-bottom: 1.3rem;
`
const TagLine = styled.div`
  margin-bottom: 0.5rem;
`
ProductTile.propTypes = {
  className: PropTypes.string
}

export default ProductTile