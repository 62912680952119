import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import { motion, useMotionValue } from "framer-motion"
import useWindow from '~utils/useWindow'


const DragSlider = ({ className, onSelect, options}) => {
  const window = useWindow()
	const boundingRef = useRef()
  const dragRef = useRef()
  const [draggerInitial, setDraggerInitial] = useState()
  const [container, setContainer] = useState(undefined)
  const [dragger, setDragger] = useState(undefined)
  const [distance, setDistance] = useState(undefined)
  const [draggerPosition, setDraggerPosition] = useState(0)

  const handleDrag = () => {
    setDragger(dragRef.current.getBoundingClientRect().x)
  }
  
  const handleClick = e => {

    const mouseX = e.clientX 
    const ContainerX = boundingRef.current.getBoundingClientRect().x 
    const ContainerWidth = boundingRef.current.getBoundingClientRect().width

    if ((mouseX - ContainerX) < 30) {
      setDraggerPosition(19 - ((ContainerWidth) / 2))
    } else if ((mouseX - ContainerX) > (ContainerWidth - 30)){
      setDraggerPosition((ContainerWidth - 19) - ((ContainerWidth) / 2))
    } else {
      setDraggerPosition((mouseX - ContainerX) - ((ContainerWidth) / 2))
    }

  }

  useEffect(() => {
    if(container, draggerInitial, dragger){
      setDistance((dragger + 15) - draggerInitial)
    }
  }, [container, draggerInitial, dragger])

  useEffect(() => {
    setDistance(draggerPosition)
  }, [draggerPosition])

  useEffect(() => {
    if (onSelect){
      if (distance < -100) {
        onSelect(0)
      }
      if ((distance >= -100) && (distance < 100)) {
        onSelect(1)
      }
      if ((distance >= 100)) {
        onSelect(2)
      }
    }
  }, [distance])


  useEffect(() => {
    // resize the container when window resizes
    if (boundingRef.current && dragRef.current) {
      setContainer(boundingRef.current.getBoundingClientRect().x + boundingRef.current.getBoundingClientRect().width)
      setDraggerInitial((boundingRef.current.getBoundingClientRect().width / 2) + boundingRef.current.getBoundingClientRect().x)
      setDragger(dragRef.current.getBoundingClientRect().x)
    }
  }, [boundingRef, dragRef, window?.innerWidth])

  const dragConstraints = () => {
    if (window?.innerWidth > 768) {
      return 300
    } else if (window?.innerWidth <= 768 && window?.innerWidth > 480) {
      return 200
    } else if (window?.innerWidth <= 480) {
      return 150
    }
  }

  return (
    <>
    <Wrap className={className}>
      <Container>
        <Ball>
          <motion.div 
            ref={dragRef}
            style={{
              width: 30,
              height: 30,
              borderRadius: 100,
              backgroundColor: "#4B3043",
              cursor: "grab",
            }}
            animate={{x: draggerPosition}}
            drag
            dragDirectionLock={true}
            dragMomentum={false}
            dragElastic={0.2}
            dragConstraints={{ right: dragConstraints(), left: (dragConstraints() * -1), top: 0, bottom: 0 }}
            dragTransition={{ 
              bounceStiffness: 1000, 
              bounceDamping: 100 
            }}
            dragElastic={0}
            whileTap={{ cursor: "grabbing" }}
            onDrag={() => handleDrag()}
          />
        </Ball>
        <Backdrop 
          ref={boundingRef}
          onClick={e => handleClick(e)}
        >
          <Labels className='s-sans'>
            {options?.map(option => (
              <Label><LabelInner>{option.name}</LabelInner></Label>
            ))}
          </Labels>
        </Backdrop>
      </Container>
    </Wrap>
    </>
  )
} 

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  min-height: 90px;
  margin-bottom: 60px;
`
const Container = styled.div`
  position: relative;
  width: 0px;
`
const Ball = styled.div`
  position: relative;
  top: 2px;
  z-index: 2;
  margin: 2px;
  left: -17px;
`
const Backdrop = styled.div`
  width: 636px;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #4B3043;
  height: 36px;
  top: 0;
  z-index: 0;
  transform: translateX(-50%);
  border-radius: 100px;
  ${mobile}{
    width: 436px;
  }
  @media (max-width: 480px){
    width: 336px;
  }
`
const Labels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 636px;
  position: relative;
  top: calc(100% + 24px);
  ${mobile}{
    width: 436px;
  }
  @media (max-width: 480px){
    width: 336px;
  }
`
const Label = styled.div`
  text-align: center;
  :last-of-type{
    justify-self: start;
  }
  :last-of-type{
    justify-self: end;
  }
`
const LabelInner = styled.div`
  display: inline-block;
`
DragSlider.propTypes = {
  className: PropTypes.string,
  onSelect: PropTypes.func,
  option: PropTypes.object,
}

export default DragSlider