import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Layout from '~components/layout'
import Section from '~components/global/section'
import Button from '~components/global/button'
import { Incrementer } from "~components/global/formElements"
import Recommendation from '~components/quiz/recommendation'
import { Levels } from '~components/quiz/quizStyles'
import Level from '~components/quiz/level'
import NameLevel from '~components/quiz/nameLevel'
import quizLevels from '~components/quiz/quizLevels'
import quizResults from '~components/quiz/quizResults'
import RenderModule from '~components/renderModule'

const Quiz = ({ data }) => {

  const [userStage, setUserStage] = useState({level: 0})
  const [product, setProduct] = useState(undefined)
  const [exit, setExit] = useState(false)

  const products = data?.allSanityProduct?.nodes

  useEffect(() => {
    setUserStage(prevState => ({
      ...prevState,
      intensityLevel: undefined,
      body: undefined,
      flavourNotes: undefined,
      level5: undefined,
      level6: undefined,
      prevLevel: undefined
    }))
  }, [])


  useEffect(() => {
    filterResults(userStage)
    console.log('userStage', userStage)
  }, [userStage])

  const filterResults = userStage => {
    let filteredResult

    if (userStage.level5) {
       filteredResult = quizResults.find(result => (
         (result.approachLevel === userStage.approachLevel) &&
        (result.intensityLevel === userStage.intensityLevel) &&
        (result.body === userStage.body) &&
        ((result.flavourNotes === userStage.flavourNotes)) &&
        ((result.level5 === userStage.level5))
      ))
    } else if (userStage.level6) {
       filteredResult = quizResults.find(result => (
         (result.approachLevel === userStage.approachLevel) &&
        (result.intensityLevel === userStage.intensityLevel) &&
        (result.body === userStage.body) &&
        ((result.flavourNotes === userStage.flavourNotes)) && 
        ((result.level5 === userStage.level5)) &&
        ((result.level6 === userStage.level6)) 
      ))
    } else if (userStage.flavourNotes) {
       filteredResult = quizResults.find(result => (
         (result.approachLevel === userStage.approachLevel) &&
        (result.intensityLevel === userStage.intensityLevel) &&
        (result.body === userStage.body) &&
        ((result.flavourNotes === userStage.flavourNotes))
      ))
    } else {
      filteredResult = quizResults.find(result => (
        (result.approachLevel === userStage.approachLevel) &&
        (result.intensityLevel === userStage.intensityLevel) &&
        (result.body === userStage.body)
      ))
    }
    
    const filteredProduct = products.find(product => (
      product?.content?.main?.title === filteredResult?.result
    ))
    setProduct(filteredProduct)
  }

  useEffect(() => {
    sendToEnd()
  }, [userStage])

  const sendToEnd = () => {
    if (userStage.level === 5 && userStage.approachLevel === 1) {
      setExit(true)
    } else if ( userStage.level === 4 && userStage.approachLevel === 0 ) {
      setExit(true)
    } else if ( userStage.level === 3 && 
      (userStage.approachLevel === 0 && 
      (userStage.intensityLevel === 1 && userStage.body === 3))) {
      setExit(true)
    } else if ( userStage.level === 3 && 
      (userStage.approachLevel === 0 && 
      (userStage.intensityLevel === 2 && userStage.body === 5))) {
      setExit(true)
    } else {
      setExit(false)
    }
  }

  const journey = quizLevels(userStage)

  return (
    <Layout>
      <Levels>
        {userStage.level === 0 &&
          <NameLevel 
            onChange={name => setUserStage(prevState => ({
              ...prevState,
              name: name,
            }))}
            onNext={name => setUserStage(prevState => ({
              ...prevState,
              name: name,
              level: 1
            }))}
          />
        }
        {/* Aproach level */}
        {userStage.level === 1 && 
          <Level 
            title={journey.levels[0].prompt}
            tiles={journey.levels[0].values}
            prevSelected={userStage.approachLevel}
            imageLeft={journey.levels[0].imageLeft}
            imageMob={journey.levels[0].imageMobile}
            onChange={selectedTile => setUserStage(prevState => ({
              ...prevState,
              approachLevel: selectedTile,
            }))}
            onNext={() => setUserStage(prevState => ({
              ...prevState,
              level: userStage.level + 1,
              prevLevel: userStage.level
            }))}
            onBack={() => 
              setUserStage(prevState => ({
              ...prevState,
              level: userStage.level - 1,
              approachLevel: undefined
            }))}
          />
        }
        {/* Intensity level */}
        {userStage.level === 2 && 
          <Level 
            title={journey.levels[1]?.prompt}
            tiles={journey.levels[1]?.options[userStage.approachLevel]?.values}
            imageRight={journey.levels[1].imageRight}
            imageMob={journey.levels[1].imageMobile}
            prevSelected={userStage.intensityLevel}
            onChange={selectedTile => setUserStage(prevState => ({
              ...prevState,
              intensityLevel: selectedTile,
            }))}
            onNext={() => setUserStage(prevState => ({
              ...prevState,
              level: userStage.level + 1,
              prevLevel: userStage.level
            }))}
            onBack={() => 
              setUserStage(prevState => ({
              ...prevState,
              level: userStage.level - 1,
              intensityLevel: undefined
            }))}
          />
        }
        {/* Body */}
        {userStage.level === 3 && 
          <Level 
            title={journey.levels[2]?.options[userStage.approachLevel]?.prompt}
            tiles={journey.levels[2]?.options[userStage.approachLevel]?.values}
            imageLeft={journey.levels[2].options[userStage.approachLevel]?.imageLeft}
            imageRight={journey.levels[2].options[userStage.approachLevel]?.imageRight}
            imageMob={journey.levels[2].options[userStage.approachLevel]?.imageMobile}
            prevSelected={userStage.body}
            onChange={selectedTile => setUserStage(prevState => ({
              ...prevState,
              body: selectedTile,
            }))}
            onNext={() => setUserStage(prevState => ({
              ...prevState,
              level: exit ? 7 : userStage.level + 1,
              prevLevel: userStage.level
            }))}
            onBack={() => 
              setUserStage(prevState => ({
              ...prevState,
              level: userStage.level - 1,
              body: undefined
            }))}
          />
        }
        {/* Flavour Notes */}
        {userStage.level === 4 && 
          <Level 
            title={journey.levels[3]?.options[userStage.approachLevel].prompt}
            tiles={journey.levels[3]?.options[userStage.approachLevel]?.values}
            prevSelected={userStage.flavourNotes}
            imageLeft={journey.levels[3].options[userStage.approachLevel]?.imageLeft}
            imageRight={journey.levels[3].options[userStage.approachLevel]?.imageRight}
            imageMob={journey.levels[3].options[userStage.approachLevel]?.imageMobile}
            slider={journey.levels[3]?.options[userStage.approachLevel]?.slider}
            onChange={selectedTile => setUserStage(prevState => ({
              ...prevState,
              flavourNotes: selectedTile,
            }))}
            onNext={() => setUserStage(prevState => ({
              ...prevState,
              level: exit ? 7 : userStage.level + 1,
              prevLevel: userStage.level
            }))}
            onBack={() => 
              setUserStage(prevState => ({
              ...prevState,
              level: userStage.level - 1,
              flavourNotes: undefined,
            }))}
          />
        }
        {/* Level 5 */}
          {userStage.level === 5 && 
          <Level 
            title={journey.levels[4]?.options[userStage.approachLevel]?.prompt}
            tiles={journey.levels[4]?.options[userStage.approachLevel]?.values}
            imageLeft={journey.levels[4].options[userStage.approachLevel]?.imageLeft}
            imageRight={journey.levels[4].options[userStage.approachLevel]?.imageRight}
            imageMob={journey.levels[4].options[userStage.approachLevel]?.imageMobile}
            prevSelected={userStage.level5}
            slider={journey.levels[4]?.options[userStage.approachLevel]?.slider}
            onChange={selectedTile => setUserStage(prevState => ({
              ...prevState,
              level5: selectedTile,
            }))}
            onNext={() => setUserStage(prevState => ({
              ...prevState,
              level: userStage.approachLevel === 1 ? 7 : userStage.level + 1,
              prevLevel: userStage.level
            }))}
            onBack={() => 
              setUserStage(prevState => ({
              ...prevState,
              level: userStage.level - 1,
              level5: undefined,
            }))}
          />
        }
        {/* Level 6 */}
        {userStage.level === 6 && 
          <Level 
            title={journey.levels[5].options[userStage.approachLevel]?.prompt}
            tiles={journey.levels[5]?.options[userStage.approachLevel]?.values}
            imageLeft={journey.levels[5].options[userStage.approachLevel]?.imageLeft}
            imageRight={journey.levels[5].options[userStage.approachLevel]?.imageRight}
            imageMob={journey.levels[5].options[userStage.approachLevel]?.imageMobile}
            prevSelected={userStage.level6}
            onChange={selectedTile => setUserStage(prevState => ({
              ...prevState,
              level6: selectedTile,
            }))}
            onNext={() => setUserStage(prevState => ({
              ...prevState,
              level: 7,
              prevLevel: userStage.level
            }))}
            onBack={() => 
              setUserStage(prevState => ({
              ...prevState,
              level: userStage.level - 1,
              level6: undefined,
            }))}
          />
        }
        {userStage.level === 7 && 
          <Recommendation 
            product={product}
            onBack={() => 
              setUserStage(prevState => ({
              ...prevState,
              level: userStage.prevLevel
            }))}
          />
        }
      </Levels>
      {data?.sanityQuiz?.modules?.map(m => (
        <RenderModule
          key={m._key}
          type={m._type}
          m={m}
          />
      ))}
    </Layout>
  )
}


Quiz.propTypes = {
  className: PropTypes.string
}

export const query = graphql`
  query{
    allSanityProduct(filter: {content: {main: {defaultGroup: {title: {eq: "Coffee Pods"}}}}}) {
    nodes {
      content {
        _rawShopify
        shopify {
          title
          defaultVariant {
            price
            variantId
          }
        }
        main {
          slug {
            current
          }
          title
          basic {
            description
            tagline
            itemsPerUnit
            packageDescription
            unitMessage
            productImages {
              asset {
                small: fixed(width: 1000) {
                  srcWebp
                }
                large: fixed(width: 2000) {
                  srcWebp
                }
                fluid {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                  srcSetWebp
                  srcWebp
                }
              }
            }
            strength
            unitMessage
            tagline
            unitName
          }
          defaultGroup {
            title
            slug {
              current
            }
          }
        }
      }
      _type
        groups {
          title
        }
      }
    }
    sanityQuiz {
      modules {
        ... on SanityRichText {
            ...richText
          }
        ... on SanityFaqs {
            ...faqs
          }
        ... on SanityTile {
            ...tile
          }
        ... on SanityHeadingText {
            ...headingText
          }
        ... on SanityTextIllo {
            ...textIllo
          }
        ... on SanityProductSlider {
            ...productSlider
          }
        ... on SanityReviewsGrid {
          ...reviewsGrid
        }
        ... on SanityGroupGrid {
          ...groupGrid
        }
        ... on SanityDivider {
            ...divider
          }
        ... on SanityIconRow {
          ...iconRow
        }
        ... on SanityCenteredText {
          ...centeredText
        }
        ... on SanityTicker {
          ...ticker
        }
        ... on SanityFullWidth {
          ...fullWidth
        }
        ... on SanityTextImage {
          ...textImage
        }
        ... on SanityContactForm {
          ...contactForm
        }
        ... on SanityVideoEmbed {
          ...videoEmbed
        }
      }
    }
  }
`



export default Quiz