import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import {
  TagLine,
  Headline,
  Input,
  Buttons,
  Next,
  LevelWrap,
  Top,
  RightImage,
  RightImageBlock,
  ImageMobBlock,
  ImageMob,
  DisabledMessage
} from '~components/quiz/quizStyles'
import Img from "gatsby-image"

const NameLevel = ({ resultName, onNext, onChange }) => {
  const [name, setName] = useState()
  const [diabledMessage, setDisabledMessage] = useState(false)

  useEffect(() => {
    if(resultName) {
      setName(resultName)
    }
  }, [resultName])

  const showDisableMessage = () => {
    setDisabledMessage(true)
  }


  return (
    <LevelWrap>
      <Top>
        <Headline className='l-serif'>What should we call you?</Headline>
        <Input placeholder={resultName ? resultName : 'name'} className='s-caps' onChange={e => setName(e.target.value)}></Input>
      </Top>
      <Buttons>
        <DisabledMessage className='s-caps' show={diabledMessage}>Please enter your name</DisabledMessage>
        <Next 
          onClick={!name || name === '' ? () => showDisableMessage() : () => onNext(name)}
          disabled={!name || name === ''}
        >Next</Next>
      </Buttons> 
      <ImageMobBlock>
        <ImageMob src='images/quiz-name.png' alt='quiz-image'/>
      </ImageMobBlock>     
      <RightImageBlock show={true}>
        <RightImage src='images/quiz-name.png' alt='quiz-image'/>
      </RightImageBlock>
    </LevelWrap>
  )
}

NameLevel.propTypes = {
  className: PropTypes.string
}

export default NameLevel